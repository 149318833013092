import {
  Action,
  Module,
  Mutation,
  VuexModule,
  config
} from "vuex-module-decorators";
import Api from "@/app/modules/_global/classes/api/Api";

config.rawError = true;

@Module({ namespaced: true })
export default class VacationStore extends VuexModule {
  private _data: object = [];

  get data(): object {
    return this._data;
  }

  @Mutation
  setData(data: object): void {
    this._data = data;
  }

  @Action
  fetch() {
    const query = `order_by=asc&per_page=100`;
    return Api.get(`/api/apps/ereporter/request-vacations?${query}`);
  }

  @Action
  fetchInRange(dateRange: Record<string, string>) {
    const query = `per_page=100between=${dateRange.start}:${dateRange.end}`;
    return Api.get(`/api/apps/ereporter/request-vacations?${query}`);
  }

  @Action
  fetchHolidaysInRange(dateRange: Record<string, string>) {
    const params = {
      "between[from]": dateRange.start,
      "between[to]": dateRange.end
    };

    return Api.get(`/api/apps/ereporter/holidays`, params);
  }

  @Action
  request(data: object) {
    return Api.post("/api/apps/ereporter/request-vacations", data);
  }

  @Action
  fetchRequest(id: number) {
    return Api.get(`/api/apps/ereporter/request-vacations/${id}`);
  }

  @Action
  cancelRequest(id: number) {
    return Api.delete(`/api/apps/ereporter/request-vacations/${id}`);
  }
}
