import { AppInfo } from "@capacitor/app";
import { Badge } from "@ionic-native/badge";
import { DeviceId, DeviceInfo } from "@capacitor/device";
import { PushNotifications } from "@capacitor/push-notifications";

import router from "@/app/router";
import store from "@/app/store";

import moment from "moment";

export default class Push {
  static registerNotifications = async () => {
    let permissionsStatus = await PushNotifications.checkPermissions();

    if (permissionsStatus.receive == "prompt") {
      permissionsStatus = await PushNotifications.requestPermissions();
    }

    if (permissionsStatus.receive != "granted") {
      throw new Error("User denied permissions!");
    }

    await PushNotifications.register();
  };

  static addListeners = async (deviceInfo: DeviceInfo) => {
    const appInfo = store.getters["app/appInfo"] as AppInfo;
    const deviceId = store.getters["device/deviceId"] as DeviceId;

    await PushNotifications.addListener("registration", token => {
      store.dispatch("profile/updateAppDevice", {
        appDevice: {
          app: appInfo.name,
          uuid: deviceId.identifier,
          platform: deviceInfo.platform,
          model: deviceInfo.model,
          os: deviceInfo.operatingSystem,
          app_version: appInfo.version,
          app_build: appInfo.build,
          bundle: appInfo.id,
          fcm_token: token.value
        }
      });
    });

    await PushNotifications.addListener("registrationError", err => {
      console.error("Registration error: ", err.error);
    });

    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      ({ notification }) => {
        if (notification.data.type == "reminder") {
          store.commit("tasks/setStatus", +notification.data.status);
          store.commit("tasks/setPeriod", +notification.data.period);

          router.push({ name: "tasks" }).catch(e => e);
        }

        if (notification.data.route == "vacation") {
          router
            .push({
              name: "vacation.requests.edit",
              params: { id: notification.data.id }
            })
            .catch(e => e);
        }
      }
    );

    await PushNotifications.addListener(
      "pushNotificationReceived",
      notification => {
        store.dispatch("tasks/fetchAll").then(({ data }) => {
          store.commit("tasks/setData", data.data);
        });

        if (
          notification.data.route == "vacation" &&
          router.currentRoute.name == "vacation.requests.edit"
        ) {
          router.replace({
            name: "vacation.requests.edit",
            params: { id: notification.data.id },
            query: { push_received_at: moment().toISOString() }
          });
        }

        if (deviceInfo.platform != "ios") {
          store.commit("snack/setSnack", {
            notification,
            status: "success",
            text: notification.title
          });
        }
      }
    );
  };

  static getDeliveredNotifications = async () => {
    const notificationList = await PushNotifications.getDeliveredNotifications();
    console.log("Delivered notifications: ", notificationList);
  };

  static async removeAllListeners() {
    await PushNotifications.removeAllListeners();
  }

  static async clearBadge() {
    await PushNotifications.removeAllDeliveredNotifications();
    await Badge.clear();
  }

  private static async _setBadge(): Promise<void> {
    await new Promise<void>(resolve => {
      PushNotifications.getDeliveredNotifications().then(list => {
        const length = list.notifications.length;

        length ? Badge.set(length) : Badge.clear();
        resolve();
      });
    });
  }
}
